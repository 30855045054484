var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useMemo, } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import Icon from '@shared/ui/icons/Icon';
import getButtonStyles from './styles';
const ButtonStyled = styled('button', { shouldForwardProp: prop => isPropValid(prop) })((props) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ display: 'inline-flex', cursor: 'pointer', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', width: 'fit-content', outline: 0, margin: 0, userSelect: 'none', textDecoration: 'none', fontStyle: 'normal', fontWeight: 400, letterSpacing: '-0.084px' }, (props.fullWidth && {
    width: '100%',
})), { 
    // size styles
    fontSize: getButtonStyles(props).bySizes[props.size].fontSize, lineHeight: getButtonStyles(props).bySizes[props.size].lineHeight, minHeight: getButtonStyles(props).bySizes[props.size].minHeight, maxHeight: getButtonStyles(props).bySizes[props.size].maxHeight, gap: getButtonStyles(props).bySizes[props.size].gap, borderRadius: getButtonStyles(props).bySizes[props.size].borderRadius, padding: props.padding || getButtonStyles(props).bySizes[props.size].padding, 
    // variant styles
    color: getButtonStyles(props)
        .byVariants[props.variant][props.color]
        .color, backgroundColor: getButtonStyles(props)
        .byVariants[props.variant][props.color]
        .backgroundColor || 'transparent', border: getButtonStyles(props)
        .byVariants[props.variant][props.color]
        .border || '1px solid transparent' }), (!props.disabled && !props.isLoading && {
    '&:hover': {
        backgroundColor: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .backgroundColorOnHover,
        borderColor: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .borderColorOnHover,
    },
    '&:active': {
        boxShadow: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .boxShadowOnActive,
        textDecoration: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .textDecorationOnActive,
    },
    '&:not(:active):focus': {
        boxShadow: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .boxShadowOnFocus,
        backgroundColor: getButtonStyles(props)
            .byVariants[props.variant][props.color]
            .backgroundColorOnFocus,
    },
})), (props.isLoading && {
    cursor: 'default',
    backgroundColor: getButtonStyles(props)
        .byVariants[props.variant][props.color]
        .backgroundColorOnLoading,
})), (props.disabled && {
    cursor: 'default',
    opacity: 0.5,
}))));
const Loader = styled(CircularProgress, { shouldForwardProp: propName => isPropValid(propName) })((props) => !props.disableSpacing && (Object.assign({ margin: '0 14.5px' }, (props.buttonSize === 'small' && {
    marginTop: 2,
    marginBottom: 2,
}))));
const ButtonComponent = (_a, ref) => {
    var { size = 'medium', variant = 'filled', color = 'primary', isLoading = false, disabled = false, fullWidth = false, disableLoaderSpacing = false, startIcon: startAdornment = undefined, endIcon: endAdornment = undefined, className = undefined, children = undefined, onClick = () => { }, endIconProps = {}, startIconProps = {} } = _a, props = __rest(_a, ["size", "variant", "color", "isLoading", "disabled", "fullWidth", "disableLoaderSpacing", "startIcon", "endIcon", "className", "children", "onClick", "endIconProps", "startIconProps"]);
    const iconSize = useMemo(() => {
        let result = 20;
        if (size === 'small') {
            result = 16;
        }
        return result;
    }, [size]);
    const onClickHandler = (event) => {
        event.currentTarget.blur();
        onClick(event);
    };
    return (_jsxs(ButtonStyled
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { ref: ref, className: className, size: size, variant: variant, color: color, disabled: disabled, isLoading: isLoading, fullWidth: fullWidth, onClick: onClickHandler, children: [isLoading && (_jsx(Loader, { color: 'inherit', size: iconSize, buttonSize: size, disableSpacing: disableLoaderSpacing })), !isLoading && (_jsxs(_Fragment, { children: [!!startAdornment && (_jsx(Icon
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    , Object.assign({}, startIconProps, { size: startIconProps.size || iconSize, IconComponent: startAdornment }))), children, !!endAdornment && (_jsx(Icon
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    , Object.assign({}, startIconProps, { size: endIconProps.size || iconSize, IconComponent: endAdornment })))] }))] })));
};
const Button = forwardRef(ButtonComponent);
export default Button;
